import LogoHorizontal from '@/assets/images/logo-horizontal2.png';
import LogoHorizontalDark from '@/assets/images/logo-horizontal-black.png';
import LogoIcon from '@/assets/images/logo-icon.png';

const Logo = ({ isDark, onlyIcon = false, ...props }) => {
    console.log(onlyIcon, props);

    if (onlyIcon) {
        return (
            <img src={!isDark ? LogoIcon : LogoIcon} {...props} />
        )
    }

    return (
        <img src={!isDark ? LogoHorizontal : LogoHorizontalDark} {...props} />
    )
}

export default Logo;